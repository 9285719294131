<template>
  <div>
    <v-container class="pb-4">
      <h1>Productos</h1>
      <v-row>
          <v-col cols="4" v-for="(product, index) of products" :key="index">
              <v-card max-width="400" @click="goToTheProduct(product)" >
                  <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="product.image"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  >
                      <v-card-title>{{product.name}}</v-card-title>
                  </v-img>

                  <v-card-subtitle class="pb-0"> Descripción </v-card-subtitle>

                  <v-card-text class="text--primary">
                      <div>{{product.description}}</div>
                  </v-card-text>

                  <v-card-subtitle class="pb-0"> Precio </v-card-subtitle>

                  <v-card-text class="text--primary">
                      <div>{{product.price}}</div>
                  </v-card-text>
                      

                  <v-card-actions>
                      <v-btn color="orange"> Compartir </v-btn>

                      <v-btn color="success"> Ver </v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Products",
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setProducsCategorie","setProduct"]),
    clearSpaceString(string) {
      return string.replace(/\s/g, "-");
    },
    goToTheProduct(product){
        this.setProduct(product.name);
        this.$router.push({ name: 'Producto', params: { product: this.clearSpaceString(product.name), _id: product._id } });
    }
  },
  computed: {
    ...mapState(["products"]),
  },
  mounted() {
    this.setProducsCategorie(this.$route.params.categorie);
  },
};
</script>
<style scoped>
    .router{
        text-decoration: none;
    }
</style>